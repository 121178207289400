import axios from "axios"
import { authHeader } from '../../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/admin/mutation",
    headers: authHeader()
});

// data: username, password
export function createMutation(payload) {
    return httpRequest.post('/', payload)
}

export function listMutation({ user_id }) {
    return httpRequest.get('/', {
        params: { user_id }
    })
}

export function getMutation(id) {
    return httpRequest.get('/' + id)
}

export default {
    createMutation,
    listMutation,
    getMutation
}