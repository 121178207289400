import { httpRequest } from "./httpRequest";
import axios from "axios";

export const fetchJoinHouse = async ({ houseId }) => {
  try {
    const res = await httpRequest.post("/tenant/balances", {
      house_id: houseId,
    });
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response;
    }
    throw error;
  }
};

export const fetchGetBalance = async () => {
  try {
    const res = await httpRequest.get("/tenant/balances");
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response;
    }
    throw error;
  }
};
