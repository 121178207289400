import axios from "axios"
import { authHeader } from '../../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/admin/user",
    headers: authHeader()
});

// data: username, password
export function list() {
    return httpRequest.get('/')
}

export function get(id) {
    return httpRequest.get('/' + id)
}
export default {
    list,
    get
}