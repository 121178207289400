import axios from "axios"
import { authHeader } from '../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/type",
    headers: authHeader(),
    withCredentials: true
});

// data: username, password
export function list() {
    return httpRequest.get('/')
}

export default {
    list,
}