import axios from "axios";
import { authHeader } from "../helpers/headers";

const httpRequest = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api/user",
  headers: authHeader(),
  withCredentials: true,
});

// data: username, password
export function me() {
  return httpRequest.get("/");
}

export function changePassword(password) {
  return httpRequest.post("change-password", { password });
}

export function updateUser({ telegram_id }) {
  return httpRequest.put("/", { telegram_id });
}

export function summary() {
  return httpRequest.get("summary");
}

export default {
  me,
  changePassword,
  summary,
  updateUser
};
