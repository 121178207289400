<template>
  <v-main>
    <v-card max-width="480" class="mx-auto rounded-0" height="100%">
      <v-responsive class="overflow-y-auto">
        <v-responsive>
          <router-view />
        </v-responsive>
        <v-responsive height="64"></v-responsive>
      </v-responsive>
    </v-card>
    <BottomNav />
  </v-main>
</template>

<script>
import BottomNav from "../components/BottomNavAuth.vue";

export default {
  components: {
    BottomNav,
  },
  data: () => ({
    pageName: "BayarIns",
  }),
};
</script>

<style>
body {
  background-color: #f7f7f7;
}
</style>