import axios from "axios"
import { authHeader } from '../../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/admin/invoice",
    headers: authHeader()
});

// data: username, password
export function createInvoice(data) {
    return httpRequest.post('/', data)
}

export function listInvoice({ type_id }) {
    return httpRequest.get('/', {
        params: { type: type_id }
    })
}

export function deleteInvoice(id) {
    return httpRequest.delete('/' + id)
}

export function getInvoice(id) {
    return httpRequest.get('/' + id)
}

export default {
    createInvoice,
    listInvoice,
    deleteInvoice,
    getInvoice
}