import typeApi from '../../api/admin/type'
import typeUserApi from '../../api/type'

const state = () => ({
    types: [],
    message: '',
})

const getters = {
    getTypes: (state) => {
        return state.types
    },
    getTypeSelect: (state) => {
        return state.types.map((item) => {
            return { value: item.id, label: item.name }
        })
    }
}

const actions = {
    listType({ commit }) {
        commit('setMessage', '')
        let req = null
        if (localStorage.getItem('role') == 'admin')
            req = typeApi.list()
        else
            req = typeUserApi.list()

        req.then(res => {
            commit('setAllList', res.data.result)
        })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    createType({ commit, dispatch }, data) {
        commit('setMessage', '')
        typeApi.create(data)
            .then(res => {
                console.log(res.status)
                dispatch('listType')
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    deleteType({ commit, dispatch }, id) {
        commit('setMessage', '')
        typeApi.deleteType(id)
            .then(res => {
                console.log(res.status)
                dispatch('listType')
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    }
}

const mutations = {
    setAllList(state, data) {
        state.types = data
    },

    setMessage(state, data) {
        state.message = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}