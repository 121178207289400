import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import user from "./modules/user";
import invoice from "./modules/invoice";
import type from "./modules/type";
import bill from "./modules/bill";
import mutation from "./modules/mutation";
import contract from "./modules/contract";
import balance from "./modules/balance";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    invoice,
    type,
    bill,
    mutation,
    contract,
    balance,
  },
});
