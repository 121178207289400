import mutationApi from '../../api/admin/mutation'
import mutationUserApi from '../../api/mutation'

const state = () => ({
    message: '',
    mutations: [],
    mutation: {}
})

const getters = {
    //
}

const actions = {
    createMutation({ commit, dispatch }, payload) {
        let formData = new FormData()
        if (payload.image)
            for (let file of payload.image) {
                formData.append('image', file, file.name)
            }
        formData.append('user_id', payload.user_id)
        formData.append('type', payload.type)
        formData.append('name', payload.name)
        formData.append('description', payload.description)
        formData.append('total', payload.total)

        mutationApi.createMutation(formData)
            .then(res => {
                commit('setMessage', 'Success')
                dispatch('user/getUser', payload.user_id, { root: true })
                dispatch('getAllMutation', { user_id: payload.user_id })
                console.log(res.status)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'Error, please try again')
            })
    },

    getAllMutation({ commit }, payload) {
        mutationApi.listMutation(payload)
            .then(res => {
                commit('setAllMutation', res.data.result)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'Error, please try again')
            })
    },

    getAllMyMutation({ commit }) {
        mutationUserApi.listMutation()
            .then(res => {
                commit('setAllMutation', res.data.result)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'Error, please try again')
            })
    },

    getMutation({ commit }, id) {
        let request = null;

        if (localStorage.getItem('role') == 'admin')
            request = mutationApi.getMutation(id)
        else
            request = mutationUserApi.getMutation(id)

        request.then(res => {
            commit('setMutation', res.data.result)
        })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'Error')
            })

    },

    resetMutation({ commit }) {
        commit('resetMutation')
    }

}

const mutations = {
    setMessage(state, payload) {
        state.message = payload
    },

    setAllMutation(state, payload) {
        state.mutations = payload
    },

    setMutation(state, payload) {
        state.mutation = payload
    },

    resetMutation(state) {
        state.mutation = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}