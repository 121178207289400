import axios from "axios"
import { authHeader } from '../../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/admin/bill",
    headers: authHeader(),
    withCredentials: true
});

export function listBill({ user_id }) {
    return httpRequest.get('/', {
        params: { user_id }
    })
}

export function getBill(id) {
    return httpRequest.get('/' + id)
}

export function updateBill(id, payload) {
    return httpRequest.put('/' + id, payload)
}

export default {
    listBill,
    getBill,
    updateBill
}