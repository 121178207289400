
export function formatNumberRupiah(number) {
    return 'Rp' + numberWithDot(number)
}

export function numberWithCommas(x) {
    x = x ?? '0'
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithDot(x) {
    x = x ?? '0'
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatDate(date) {
    return new Date(date).toLocaleDateString(
        'id-ID',
        {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      );
}