<template>
  <v-container>
    <v-row dense align="stretch">
      <ColSummary />
      <v-col v-for="(item, i) in bills" :key="i" cols="12">
        <CardBill :bill="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CardBill from '../components/CardBill.vue'
import ColSummary from '../components/ColSummary.vue'

export default {
  name: 'Home',

  components: {
    CardBill,
    ColSummary
  },

  data: () => ({
    type_id: ''
  }),
  computed: {
    ...mapGetters('type', ['getTypeSelect']),
    ...mapState({
      bills: (state) => state.bill.bills
    })
  },
  methods: {
    ...mapActions('type', ['listType']),
    ...mapActions('bill', ['getAllMyBill', 'getBillSummary']),
    getBills() {
      this.getBillSummary()
      this.getAllMyBill({ type_id: this.type_id })
    }
  },
  mounted() {
    this.listType()
    this.getBills()
  }
}
</script>
