import axios from "axios"
import { authHeader } from '../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/tenant/mutations",
    headers: authHeader(),
    withCredentials: true
});

export function listMutation() {
    return httpRequest.get('/')
}

export function getMutation(id) {
    return httpRequest.get('/' + id)
}

export default {
    listMutation,
    getMutation
}