<template>
  <v-card :to="'/bill/' + bill.id" color="white" dark>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title
          class="text-h5 font-weight-bold hitam-nopekat"
          v-text="formatNumberRupiah(bill.total)"
        ></v-card-title>

        <v-card-subtitle class="black--text text--secondary font-italic">
          {{ formatDate(bill.created_at) }} | {{ bill.name }} {{ addTextCanceled }}
        </v-card-subtitle>
      </div>

      <v-avatar class="ma-5" tile>
        <v-icon x-large v-if="isCanceled" color="grey">mdi-close-box</v-icon>
        <v-icon x-large v-else-if="bill.paid_at != null" color="green">mdi-checkbox-marked</v-icon>
        <v-icon x-large v-else color="red">mdi-alert-box</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import { formatNumberRupiah, formatDate } from '../helpers/helper'
export default {
  props: {
    bill: Object
  },
  methods: {
    formatNumberRupiah,
    formatDate
  },
  computed: {
    textColor() {
      return this.isCanceled ? 'grey--text' : this.bill.paid_at != null ? 'green--text' : 'red--text'
    },
    isCanceled() {
      return this.bill.invoice ? this.bill.invoice.status === 'canceled' : false
    },
    addTextCanceled() {
      return this.isCanceled ? '| Canceled' : ''
    }
  }
}
</script>

<style scoped>
.hitam-nopekat {
  color: #333 !important;
}
</style>