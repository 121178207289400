<template>
  <v-main>
    <v-card max-width="480" class="mx-auto rounded-0" height="100%">
      <v-responsive class="overflow-y-auto">
        <v-responsive>
          <router-view />
        </v-responsive>
        <v-responsive height="64"></v-responsive>
      </v-responsive>

      <button-nav />
    </v-card>
  </v-main>
</template>

<script>
import ButtonNav from '../components/ButtonNav.vue'
import { fetchGetBalance } from '../api/balance'
import { mapState } from 'vuex'

export default {
  components: {
    ButtonNav
  },

  data: () => ({
    pageName: 'BayarIns'
  }),

  methods: {
    async getBalance() {
      const res = await fetchGetBalance()
      if (!res.result || res.result.status !== 'active') this.$router.push('/new/join')
    }
  },

  computed: {
    ...mapState({
      contract: (state) => state.contract.contract
    })
  },

  watch: {
    contract: function (newC) {
      if (newC && ['owner', 'admin'].includes(newC.role)) this.$router.push('/new/admin')
    }
  },

  mounted() {
    this.getBalance()
    this.$store.dispatch('contract/getContract')
  }
}
</script>

<style>
body {
  background-color: #f7f7f7;
}
</style>