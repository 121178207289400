import axios from "axios"
import { authHeader } from '../../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/admin/type",
    headers: authHeader()
});

// data: username, password
export function list() {
    return httpRequest.get('/')
}

export function create(data) {
    return httpRequest.post('/', data)
}

export function deleteType(id) {
    return httpRequest.delete('/' + id)
}
export default {
    list,
    create,
    deleteType
}