import axios from "axios";

const accessToken = localStorage.getItem("accessToken");

export const httpRequest = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  headers: {
    Authorization: accessToken ? "Bearer " + accessToken : null,
    accept: "application/json",
  },
  withCredentials: true,
});
