<template>
  <v-bottom-navigation
    fixed
    color="blue darken-1"
    hide-on-scroll
    grow
    max-width="480"
    width="100%"
    style="bottom: 0; left: 50%; transform: translate(-50%); box-shadow: none"
    elevation="0"
  >
    <v-btn to="/balance" height="100%">
      <span>Saldo</span>

      <v-icon>mdi-cash</v-icon>
    </v-btn>

    <v-btn to="/" height="100%">
      <span>Beranda</span>

      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn to="/account" height="100%">
      <span>Akun</span>

      <v-icon>mdi-account</v-icon>
    </v-btn>

    <v-btn to="/admin" height="100%" v-if="adminStatus">
      <span>Admin</span>

      <v-icon>mdi-shield-account</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  computed: {
    adminStatus() {
      return localStorage.getItem('role') == 'admin' ? true : false
    }
  }
}
</script>