import billApi from '../../api/admin/bill'
import billUserApi, { fetchGetSummary } from '../../api/bill'

const state = () => ({
    bill: null,
    bills: [],
    summary: null,
    message: ''
})

const getters = {
    //
}

const actions = {
    getAllBill({ commit }, payload) {
        commit('setMessage', '')
        billApi.listBill(payload)
            .then(res => {
                commit('setAllBills', res.data.result)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    getBill({ commit }, id) {
        commit('setBill', {})
        commit('setMessage', '')

        let request = null
        if (localStorage.getItem('role') == 'admin')
            request = billApi.getBill(id)
        else
            request = billUserApi.getBill(id)

        request.then(res => {
            commit('setBill', res.data.result)
        })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    updateBill({ commit, dispatch }, payload) {
        commit('setMessage', '')

        const id = payload.id
        if (payload.image) {
            let formData = new FormData;
            for (let file of payload.image) {
                formData.append('image', file, file.name)
            }
            formData.append('note', payload.note)
            formData.append('type', payload.type)
            payload = formData
        }

        billApi.updateBill(id, payload)
            .then(res => {
                console.log(res.status)
                dispatch('getBill', payload.id)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    getAllMyBill({ commit }, payload) {
        commit('setMessage', '')
        billUserApi.listBill(payload)
            .then(res => {
                commit('setAllBills', res.data.result)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    getBillSummary({ commit }) {
        commit('setMessage', '')
        fetchGetSummary()
            .then(res => {
                commit('setBillSummary', res.data.result)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },



    resetBill({ commit }) {
        commit('resetBill')
    }
}

const mutations = {
    setAllBills(state, payload) {
        state.bills = payload
    },

    setMessage(state, payload) {
        state.message = payload
    },

    setBill(state, payload) {
        state.bill = payload
    },

    setBillSummary(state, payload) {
        state.summary = payload
    },

    resetBill(state) {
        state.bill = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}