export function authHeader() {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken != null)
        return {
            Authorization: 'Bearer ' + accessToken,
            accept: 'application/json'
        }

    return {
        accept: 'application/json'
    }
}