import axios from "axios"
import { authHeader } from '../helpers/headers'

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api/tenant/bills",
    headers: authHeader(),
    withCredentials: true
});

export function listBill({ type_id }) {
    return httpRequest.get('/', {
        params: { type_id }
    })
}

export function getBill(id) {
    return httpRequest.get('/' + id)
}

export function fetchGetSummary() {
    return httpRequest.get('/summary')
}

export default {
    listBill,
    getBill
}