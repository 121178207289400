<template>
  <v-bottom-navigation fixed color="blue darken-1" hide-on-scroll grow max-width="480" width="100%"
    style="bottom: 0; left: 50%; transform: translate(-50%); box-shadow: none" elevation="0">
    <v-btn @click="logout" height="100%" max-width="480">
      <span>Logout</span>

      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.clear()
      window.location.href = '/auth/login'
    }
  }
}
</script>