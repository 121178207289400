import { fetchListContract, fetchGetContract } from "../../api/contract";

const state = () => ({
  contract: null,
  contracts: [],
  message: "",
});

const getters = {
  //
};

const actions = {
  async listAllContract({ commit }) {
    commit("setMessage", "");
    try {
      const res = await fetchListContract();
      commit("setListContract", res.result);
    } catch (error) {
      commit("setMessage", error.data.message ?? "error, please try again");
    }
  },

  async getContract({ commit }) {
    commit("setMessage", "");
    try {
      const res = await fetchGetContract();
      commit("setContract", res.result);
    } catch (error) {
      commit("setMessage", error.data.message ?? "error, please try again");
    }
  },
};

const mutations = {
  setListContract(state, payload) {
    state.contracts = payload;
  },

  setContract(state, payload) {
    state.contract = payload;
  },

  setMessage(state, payload) {
    state.message = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
