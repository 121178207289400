<template>
  <v-main>
    <v-card max-width="480" class="mx-auto rounded-0" height="100%">
      <v-responsive class="overflow-y-auto">
        <v-responsive>
          <router-view />
        </v-responsive>
        <v-responsive height="64"></v-responsive>
      </v-responsive>
    </v-card>
    <BottomNavSimple />
  </v-main>
</template>

<script>
import BottomNavSimple from '../components/BottomNavSimple.vue'
export default {
  components: {
    BottomNavSimple
  },
  data: () => ({
    pageName: 'BayarIns'
  })
}
</script>

<style>
body {
  background-color: #f7f7f7;
}
</style>