// import invoiceAdmin from '../../api/admin/invoice'

import invoice from "../../api/admin/invoice"
import router from "../../router"

const state = () => ({
    formCreate: {
        portion: {
            selectedUser: [],
            userPortion: []
        }
    },
    message: '',
    invoices: [],
    invoice: {}
})

const getters = {
    getBilledUserPortion: (state, getters, rootState, rootGetters) => {
        return state.formCreate.portion.selectedUser.map((item) => {
            return { id: rootGetters['user/getUsers'][item].id, name: rootGetters['user/getUsers'][item].name, total: state.formCreate.portion.userPortion[item] }
        })
    }
}

const actions = {
    setPortionSelectedUser({ commit }, payload) {
        commit('setPortionSelectedUser', payload)
    },

    setPortionUserPortion({ commit }, payload) {
        commit('setPortionUserPortion', payload)
    },

    createInvoice({ commit, getters, dispatch }, { type_id, name, total, note, image }) {
        let formData = new FormData()
        if (image)
            for (let file of image) {
                formData.append('image', file, file.name)
            }
        formData.append('type_id', type_id)
        formData.append('name', name)
        formData.append('total', total)
        formData.append('note', note)
        formData.append('portion', JSON.stringify(getters['getBilledUserPortion']))
        invoice.createInvoice(formData)
            .then(res => {
                console.log(res.status)
                commit('setMessage', 'Invoice berhasil dibuat')
                dispatch('listInvoice', { type_id: null })
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    listInvoice({ commit }, { type_id }) {
        invoice.listInvoice({ type_id })
            .then(res => {
                commit('setAllInvoice', res.data.result)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    getInvoice({ commit }, id) {
        commit('setInvoice', {})
        invoice.getInvoice(id)
            .then(res => {
                commit('setInvoice', res.data.result)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'error, please try again')
            })
    },

    deleteInvoice({ commit }, id) {
        invoice.deleteInvoice(id)
            .then(res => {
                console.log(res.status)
                router.go(-1)
            })
        console.log(commit)

    }
}

const mutations = {
    setPortionSelectedUser(state, payload) {
        state.formCreate.portion.selectedUser = payload
    },

    setPortionUserPortion(state, payload) {
        state.formCreate.portion.userPortion = payload
    },

    setAllInvoice(state, payload) {
        state.invoices = payload
    },

    setMessage(state, payload) {
        state.message = payload
    },

    setInvoice(state, payload) {
        state.invoice = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}