<template>
  <v-card :color="color" dark>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h6" v-text="totalFormated"></v-card-title>

        <v-card-subtitle class="font-italic" v-text="name"></v-card-subtitle>
      </div>

      <v-avatar class="ma-5" tile>
        <v-icon x-large>{{ icon }}</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import { formatNumberRupiah } from '../helpers/helper'
export default {
  props: {
    icon: String,
    total: Number,
    name: String,
    color: String
  },
  methods: {
    formatNumberRupiah
  },
  computed: {
    totalFormated(){
      return formatNumberRupiah(this.total)
    }
  }
}
</script>