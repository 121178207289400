<template>
  <v-col cols="12" v-if="summary">
    <CardStats
      name="Sudah Dibayar"
      :total="summary.paid"
      :color="color"
      icon="mdi-check-bold"
      class="mr-1 mb-1 flex-grow-1"
    />
    <CardStats
      name="Belum Dibayar"
      :total="summary.unpaid"
      :color="color"
      icon="mdi-close-thick"
      class="mr-1 mb-1 ma-0"
    />
  </v-col>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CardStats from './CardStats.vue'

export default {
  components: {
    CardStats
  },
  data: () => ({
    color: 'blue darken-2'
  }),
  methods: {
    ...mapActions('user', ['getSummary']),
    ...mapActions('bill', ['getBillSummary'])
  },
  computed: {
    ...mapState({
      summary: (state) => state.bill.summary
    })
  },
  mounted() {
    this.getBillSummary()
  }
}
</script>