import userApi from '../../api/user'
import userAdminApi from '../../api/admin/user'

const state = () => ({
    message: '',
    user: null,
    users: [],
    summary: {}
})

const getters = {
    getUsers: (state) => {
        return state.users
    },
    getUserSelect: (state) => {
        return state.users.map((item) => {
            return { label: item.name, value: item.id }
        })
    },
}

const actions = {
    getMe({ commit }) {
        userApi.me()
            .then(res => {
                commit('setUserMe', res.data.result)
                if (localStorage.getItem('role') != res.data.result.role) {
                    localStorage.setItem('role', res.data.result.role)
                    location.reload()
                }
            })
    },

    getAllUser({ commit }) {
        userAdminApi.list()
            .then(res => {
                //
                commit('setAllUsers', res.data.result)
            })
    },

    getUser({ commit }, id) {
        userAdminApi.get(id)
            .then(res => {
                commit('setUserMe', res.data.result)
            })
    },

    getSummary({ commit }) {
        userApi.summary()
            .then(res => {
                commit('setSummary', res.data.result)
            })
    },

    changePassword({ commit }, password) {
        userApi.changePassword(password)
            .then(res => {
                commit('setMessage', 'Change password success!')
                console.log(res.status)
            })
            .catch(err => {
                commit('setMessage', err.response.data.message ?? 'Error, please try again')
            })
    }
}

const mutations = {
    setUserMe(state, data) {
        state.user = data
    },

    setAllUsers(state, data) {
        state.users = data
    },

    setSummary(state, data) {
        state.summary = data
    },

    setMessage(state, data){
        state.message = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}