import auth from "../../api/auth";
import router from "../../router";

const initState = () => ({
  verified: false,
  codeSended: false,
  allowSend: true,
  telegram_id: "",
  message: "",
  timerCount: 0,
  step: 1,
});

const state = initState();

const getters = {
  //
};

const actions = {
  sendCodeTelegram({ commit, state }, { telegram_id }) {
    commit("setMessage", "");
    auth
      .sendCodeTelegram({ telegram_id })
      .then(() => {
        commit("setCodeSended", { telegram_id });
        commit("setTimerCount", 10);

        const timerI = setInterval(() => {
          commit("setTimerCount", state.timerCount - 1);
          if (state.timerCount < 0) {
            commit("setAllowSend");
            clearInterval(timerI);
          }
        }, 1000);
      })
      .catch((err) => {
        commit(
          "setMessage",
          err.response.data.message !== undefined
            ? err.response.data.message
            : "Gagal mengirim kode, silahkan coba lagi"
        );
      });
  },

  verifyCode({ commit, state }, { code }) {
    // check if code true
    // if true commit verified, delete message
    // if false commit message
    const codes = [
      Number((state.telegram_id * 3).toString().slice(0, 6)),
      Number((state.telegram_id * 4).toString().slice(0, 6)),
      Number((state.telegram_id * 5).toString().slice(0, 6)),
      Number((state.telegram_id * 6).toString().slice(0, 6)),
      Number((state.telegram_id * 7).toString().slice(0, 6)),
    ];
    if (codes.includes(Number(code))) {
      commit("setCodeVerified");
      commit("setMessage", "");
      commit("setStep", 2);
    } else commit("setMessage", "Code wrong, please check again");
  },

  sendRegister({ commit }, data) {
    commit("setMessage", "");
    auth
      .register(data)
      .then((res) => {
        router.push("/auth/login");
        console.log(res.status);
      })
      .catch((err) => {
        commit(
          "setMessage",
          err.response.data.errors
            ? Object.values(err.response.data.errors)[0]
            : "Something error, please try again"
        );
        console.log(
          err.response.data.errors
            ? Object.values(err.response.data.errors)[0]
            : "Something error, please try again"
        );
      });
  },

  sendLogin({ commit }, data) {
    // sendlogin
    // if status ok then set accesstoken and redirect
    // if error set message
    commit("setMessage", "");
    auth
      .login(data)
      .then((res) => {
        const { access_token, role } = res.data.result;
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("role", role);
        // router.push('/')
        window.location.href = "/";
      })
      .catch((err) => {
        commit(
          "setMessage",
          err.response.data.message ?? "Login error, please try again"
        );
      });
  },

  resetTelegram({ commit }) {
    commit("resetTelegram");
  },

  resetRegister({ commit }) {
    commit("resetRegister");
  },
};

const mutations = {
  setCodeSended(state, { telegram_id }) {
    state.codeSended = true;
    state.telegram_id = telegram_id;
    state.allowSend = false;
  },

  setAllowSend(state) {
    state.allowSend = true;
  },

  setCodeVerified(state) {
    state.verified = true;
  },

  setMessage(state, message) {
    state.message = message;
  },

  setTimerCount(state, data) {
    state.timerCount = data;
  },

  setStep(state, data) {
    state.step = data;
  },

  resetTelegram(state) {
    state.codeSended = false;
    state.message = "";
  },

  resetRegister(state) {
    Object.assign(state, initState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
