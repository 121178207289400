import axios from "axios"

const httpRequest = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api",
    headers: {
        accept: "application/json"
    },
    withCredentials: true
});

export const signCSRF = () => {
    
}

// data: username, password
export function login(data) {
    return httpRequest.post('auth/login', data)
}

// data: username, password, telegram, fullname
export function register(data) {
    return httpRequest.post('auth/register', data)
}

// data: username, password, telegram, fullname
export function sendCodeTelegram(data) {
    return httpRequest.post('telegram/code', data)
}

// data: username, password
export function sendForgotPassword({email}) {
    return httpRequest.post('auth/forgot-password', {email})
}

// data: username, password
export function sendResendVerification({email}) {
    return httpRequest.post('auth/resend-verification', {email})
}

// data: username, password
export function sendResetPassword({token, email, password, password_confirmation}) {
    return httpRequest.post('auth/reset-password', {token, email, password, password_confirmation})
}

export default {
    login,
    register,
    sendCodeTelegram
}