import { fetchGetBalance } from "../../api/balance";

const state = () => ({
  balance: null,
  message: "",
});

const getters = {
  //
};

const actions = {
  async getBalance({ commit }) {
    commit("setMessage", "");
    try {
      const res = await fetchGetBalance();
      commit("setBalance", res.result);
    } catch (error) {
      commit("setMessage", error.data.message ?? "error, please try again");
    }
  },
};

const mutations = {
  setBalance(state, payload) {
    state.balance = payload;
  },

  setMessage(state, payload) {
    state.message = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
