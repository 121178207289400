import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=3a5c068c"
import script from "./Auth.vue?vue&type=script&lang=js"
export * from "./Auth.vue?vue&type=script&lang=js"
import style0 from "./Auth.vue?vue&type=style&index=0&id=3a5c068c&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VMain } from 'vuetify/lib/components/VMain';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VCard,VMain,VResponsive})
