import { httpRequest } from "./httpRequest";
import axios from "axios";

export const fetchListContract = async () => {
  try {
    const res = await httpRequest.get("/tenant/contracts/all");
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response;
    }
    throw error;
  }
};

export const fetchGetContract = async () => {
  try {
    const res = await httpRequest.get("/tenant/contracts");
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response;
    }
    throw error;
  }
};
