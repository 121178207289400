import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Default from "../layouts/Default.vue";
import Auth from "../layouts/Auth.vue";
import NoMenu from "../layouts/NoMenu.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "login",
        name: "Login",
        meta: {
          guest: true,
        },
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/Auth/Login.vue"),
      },
      {
        path: "register",
        name: "Register",
        meta: {
          guest: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Auth/RegisterComplete.vue"
          ),
      },
      {
        path: "verify",
        name: "Verification",
        meta: {
          guest: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Auth/Telegram.vue"
          ),
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        meta: {
          guest: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Auth/ForgotPassword.vue"
          ),
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        meta: {
          guest: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Auth/ResetPassword.vue"
          ),
      },
      {
        path: "email-verified",
        name: "EmailVerified",
        meta: {
          guest: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Auth/EmailVerified.vue"
          ),
      },
      {
        path: "resend-verification",
        name: "ResendVerification",
        meta: {
          guest: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Auth/ResendVerification.vue"
          ),
      },
    ],
  },

  {
    path: "/",
    name: "DefaultLayout",
    component: Default,
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          requiresAuth: true,
        },
        component: Home,
      },
      {
        path: "/account",
        name: "Account",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/Account.vue"),
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/ChangePassword.vue"
          ),
      },
      {
        path: "/change-telegram",
        name: "ChangeTelegram",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/ChangeTelegramId.vue"
          ),
      },
      {
        path: "/bill/:id",
        name: "DetailBill",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/DetailBill.vue"),
      },
      {
        path: "/balance",
        name: "Balance",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "balance" */ "../views/Balance.vue"),
      },
      {
        path: "/mutation/:id",
        name: "DetailMutation",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../views/DetailMutation.vue"
          ),
      },
      {
        path: "/admin",
        name: "Admin",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/Admin/Menu.vue"),
      },
      {
        path: "/invoice/:id",
        name: "DetailInvoice",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/DetailInvoice.vue"),
      },
      {
        path: "/admin/type",
        name: "AdminType",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Admin/Type/ManageType.vue"
          ),
      },
      {
        path: "/admin/balance",
        name: "AdminBalance",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Admin/Balance/ManageBalance.vue"
          ),
      },
      {
        path: "/admin/invoice/create",
        name: "AdminInvoiceCreate",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Admin/Invoice/CreateInvoice.vue"
          ),
      },
      {
        path: "/admin/invoice/list",
        name: "AdminInvoiceList",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Admin/Invoice/ListInvoice.vue"
          ),
      },
      {
        path: "/admin/invoice/update-invoice/:id",
        name: "AdminInvoiceUpdate",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Admin/Invoice/UpdateInvoice.vue"
          ),
      },
      {
        path: "/admin/invoice/bill",
        name: "AdminInvoiceBill",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Admin/Invoice/ListBill.vue"
          ),
      },
      {
        path: "/admin/invoice/update-bill/:id",
        name: "AdminInvoiceUpdateBill",
        meta: {
          requiresAuth: true,
          is_admin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Admin/Invoice/UpdateBill.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "DefaultLayout",
    component: Auth,
    children: [
      {
        path: "/landing",
        name: "Landing Page",
        meta: {
          guest: true,
        },
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/Landing.vue"),
      },
    ]
  },

  {
    path: "/new",
    component: NoMenu,
    children: [
      {
        path: "join",
        name: "Join",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Balance/JoinBalance.vue"
          ),
      },
      {
        path: "admin",
        name: "Admin",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/Balance/Admin.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("accessToken") == null) {
      next({
        path: "/landing",
      });
    } else {
      let role = localStorage.getItem("role");
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (role == "admin") {
          next();
        } else {
          next({
            path: "/",
          });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("accessToken") == null) {
      next();
    } else {
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});
export default router;
